import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from '../pages/Login/'

import { AuthProvider } from '../context/Auth/AuthContext'
import { TicketsContextProvider } from '../context/Tickets/TicketsContext'
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext'
import Route from './Route'

import General from './General'

const Routes = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <TicketsContextProvider>
                        <WhatsAppsProvider>
                            <General />
                        </WhatsAppsProvider>
                    </TicketsContextProvider>
                </Switch>
                <ToastContainer autoClose={3000} />
            </AuthProvider>
        </BrowserRouter>
    )
}

export default Routes
