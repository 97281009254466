import React from "react";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",

        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
	},
}));

const BackdropLoading = ({text}) => {
	const classes = useStyles();
	return (
        <Backdrop className={classes.backdrop} open={true}>
            <div>
                <CircularProgress color="inherit" />
                {text && <Typography>{text}</Typography>}
            </div>
        </Backdrop>
    )
};

export default BackdropLoading;
