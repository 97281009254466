import { Grid, TextField } from '@material-ui/core'
import { Field } from 'formik'

const SleepForm = props => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    error={props.touched?.title && props.errors?.title}
                    helperText={props.touched?.title && props.errors?.title}
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={TextField}
                    name="time"
                    label="Espera em segundos"
                    type="number"
                    fullWidth
                    variant="outlined"
                    max={60 * 60 * 10}
                    error={props.touched?.time && props.errors?.time}
                    helperText={props.touched?.time && props.errors?.time}
                    {...props.getFieldProps(`time`)}
                />
            </Grid>
        </Grid>
    )
}

export default SleepForm
