import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Popover,
} from '@material-ui/core'
import MoodIcon from '@material-ui/icons/Mood'
import { useTheme } from '@material-ui/styles'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { useField } from 'formik'
import { useState } from 'react'

const EmojiOptions = props => {
    const { disabled, handleAddEmoji } = props
    const theme = useTheme()
    const [anchor, setAnchor] = useState()

    const handleClick = (ev) => {
        setAnchor(ev.currentTarget)
    }

    const showEmoji = !!anchor
    return (
        <>
            <IconButton
                aria-describedby="emojiPicker"
                aria-label="emojiPicker"
                component="span"
                disabled={disabled}
                onClick={handleClick}>
                <MoodIcon />
            </IconButton>
            <Popover
                id="emojiPicker"
                open={showEmoji}
                anchorEl={anchor}
                onClose={e => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <Picker
                    dynamicWidth={true}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                    theme={theme.mode}
                />
            </Popover>
        </>
    )
}

const MessageInput = ({ innerRef, helperText, error, ...props }) => {
    const [field, , helpers] = useField(props.name)

    const handleAddEmoji = e => {
        let emoji = e.native

        const el = innerRef.current
        const firstHalfText = el.value.substring(0, el.selectionStart)
        const secondHalfText = el.value.substring(el.selectionEnd)
        const newCursorPos = el.selectionStart + emoji.length

        helpers.setValue(`${firstHalfText}${emoji}${secondHalfText}`)
        innerRef.current.setSelectionRange(newCursorPos, newCursorPos)
    }

    const handleChange = event => {
        helpers.setValue(event.target.value)
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth={props.fullWidth}
            className={props.className}
            error={error}>
            <InputLabel shrink={!!field.value} htmlFor={props.name}>
                {props.label}
            </InputLabel>
            <OutlinedInput
                id={props.name}
                name={props.name}
                multiline={true}
                minRows={3}
                endAdornment={
                    <InputAdornment position="end">
                        <EmojiOptions
                            disabled={props.disabled}
                            handleAddEmoji={handleAddEmoji}
                        />
                    </InputAdornment>
                }
                onChange={handleChange}
                {...field}
                value={field.value || ''}
                inputRef={innerRef}
                {...props}
            />

            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default MessageInput
