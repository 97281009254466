import { Field } from 'formik'
import FormikCustomFieldAutocomplete from 'components/CustomFieldAutocomplete/FormikCustomFieldAutocomplete'
import MessageWithPicker from '../../components/MessageWithPicker'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const AskForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    error={props.touched?.title && props.errors?.title}
                    helperText={props.touched?.title && props.errors?.title}
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Pergunta"
                    error={props.touched?.message && props.errors?.message}
                    helperText={props.touched?.message && props.errors?.message}
                    {...props.getFieldProps(`message`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={FormikCustomFieldAutocomplete}
                    name="answer"
                    label="Salvar Resposta"
                    fullWidth
                    variant="outlined"
                    error={props.touched?.answer && props.errors?.answer}
                    helperText={props.touched?.answer && props.errors?.answer}
                    {...props.getFieldProps('answer')}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                O Bot enviará a pergunta configurada e aguardará a resposta do
                contato.
                <br />A resposta ficará salva a na variável configurada
            </Alert>
        </Grid>
    )
}

export default AskForm
