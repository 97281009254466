import { makeStyles } from '@material-ui/core'
import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import styles from '../../general.module.scss'
import { useEffect, useState } from 'react'
import { uniq } from 'lodash'
import api from 'services/api'
import CallSplitIcon from '@material-ui/icons/CallSplit'


const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
    },

    edgesContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid',
        paddingBlock: 5,
    },

    edges: {
        position: 'relative',
        fontSize: '0.6rem',
        color: theme.palette.textPrimary,
        lineHeight: 1,
        paddingInline: 5,
    },
}))

const operators = {
    equal: 'igual',
    not_equal: 'diferente',
    contains: 'contém',
    not_contains: 'não contém',
    empty: 'vazio',
    not_empty: 'não vazio',
    starts: 'inicia com',
    not_starts: 'não inicia com',
    ends: 'finaliza com',
    not_ends: 'não finaliza com',
}

const ConditionNode = myNode => {
    const { data, ...props } = myNode

    const classes = useStyles()
    const [customFields, setCustomFields] = useState([])

    useEffect(() => {
        const toSearch = []

        if (!data.conditions?.length) return

        data.conditions.forEach(condition => {
            toSearch.push(condition.value)

            if (condition.type === 'customField')
                toSearch.push(condition.comparison)
        })

        api.get('/custom-field', {
            searchParams: { idIn: uniq(toSearch) },
            pageNumber: 1,
        }).then(r => {
            setCustomFields(r.data.customFields)
        })
    }, [data.conditions])

    const renderCondition = condition => {
        return (
            <div key={condition.id}>
                <b>{customFields.find(c => c.id === condition.value)?.name}</b>{' '}
                {operators[condition.operator]}
                {': '}
                <i>
                    {condition.type === 'customField'
                        ? customFields.find(c => c.id === condition.comparison)
                              ?.name
                        : condition.comparison}
                </i>
            </div>
        )
    }

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Condição"
                noContainer
                icon={
                    <CallSplitIcon style={{ transform: 'rotate(180deg)' }} />
                }>
                <div className={[styles.content, classes.content].join(' ')}>
                    {data.conditions?.length
                        ? data.conditions.map(renderCondition)
                        : 'Clique para configurar!'}
                </div>

                <div className={classes.edgesContainer}>
                    <div className={[classes.edgeNo, classes.edges].join(' ')}>
                        <CustomStartHandle
                            position={Position.Left}
                            id={props.id + 'no'}
                            nodeId={props.id}
                        />
                        Não
                    </div>

                    <div className={[classes.edgeYes, classes.edges].join(' ')}>
                        Sim
                        <CustomStartHandle
                            position={Position.Right}
                            id={props.id + 'yes'}
                            nodeId={props.id}
                        />
                    </div>
                </div>
            </CustomNodeBase>
        </div>
    )
}

export default ConditionNode
