import axios from 'axios'
import { stringify as toQuery } from 'qs'

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    withCredentials: true,
})

export const openApi = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
})

const _get = api.get
api.get = (route, params, options) => {
    const glue = route.indexOf('?') !== -1 ? '&' : '?'
    if (params) route += glue + toQuery(params)

    return _get(route, options)
}

export default api
