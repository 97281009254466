import React, { useState, useEffect, useRef } from "react"

import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"

import { makeStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CircularProgress from "@material-ui/core/CircularProgress"

import { i18n } from "../../translate/i18n"

import api from "../../services/api"
import toastError from "../../errors/toastError"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}))

const CustomFieldSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
})

const CustomFieldModal = ({open, onClose, fieldId, initialValues, onSave}) => {
    const classes = useStyles()
    const isMounted = useRef(true)

    const initialState = {
        name: "",
    }

    const [customField, setCustomField] = useState(initialState)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        const fetchField = async () => {
            if (initialValues) {
                setCustomField(prevState => {
                    return { ...prevState, ...initialValues }
                })
            }

            if (!fieldId) return

            try {
                const { data } = await api.get(`/custom-field/${fieldId}`)
                if (isMounted.current) setCustomField(data)
            } catch (err) {
                toastError(err)
            }
        }

        fetchField()
    }, [fieldId, open, initialValues])

    const handleClose = () => {
        onClose()
        setCustomField(initialState)
    }

    const handleSaveCustomField = async values => {
        try {
            if (fieldId) {
                await api.put(`/custom-field/${fieldId}`, values)
                handleClose()
            } else {
                const { data } = await api.post("/custom-field", values)
                if (onSave) {
                    onSave(data)
                }
                handleClose()
            }
            toast.success(i18n.t("customField.toasts.success"))
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {fieldId
                        ? `${i18n.t("customField.form.edit")}`
                        : `${i18n.t("customField.form.add")}`}
                </DialogTitle>
                <Formik
                    initialValues={customField}
                    enableReinitialize={true}
                    validationSchema={CustomFieldSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveCustomField(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <Field
                                    as={TextField}
                                    label={i18n.t("customField.table.name")}
                                    name="name"
                                    autoFocus
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name && errors.name}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    {i18n.t("customField.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {i18n.t("customField.buttons.save")}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default CustomFieldModal
