import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const UserSelect = ({ value, onChange, multiple = false, label = i18n.t("users.title")}) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const { data } = await api.get("/users");
            setUsers(data.users);
        } catch (err) {
            toastError(err);
        }
    };

    const handleChange = e => {
        onChange(e.target.value);
    };

    return (
        <div>
            <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel shrink={value ? true : false}>{label}</InputLabel>
                <Select
                    label={label}
                    multiple={multiple}
                    labelWidth={60}
                    value={value}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    renderValue={selected => {
                        const usersToShow = users.filter(
                            user =>
                                user.id === selected ||
                                (Array.isArray(selected) &&
                                    selected.includes(user.id))
                        );
                        return usersToShow.map(user => user.name).join(", ");
                    }}>
                    {!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
                    {users.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default UserSelect;
