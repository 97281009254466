import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MessageForm from '../../nodes/MessageNode/MessageForm'
import MenuForm from '../../nodes/MenuNode/MenuForm'
import SleepForm from '../../nodes/SleepNode/SleepForm'
import AskForm from '../../nodes/AskNode/AskForm'
import QueueForm from '../../nodes/QueueNode/QueueForm'
import UserForm from '../../nodes/UserNode/UserForm'
import NotifyForm from '../../nodes/NotifyNode/NotifyForm'
import ConditionForm from '../../nodes/ConditionNode/ConditionForm'
import TagForm from '../../nodes/TagNode/TagForm'
import { Form, Formik } from 'formik'
import FindPersonForm from 'pages/ChatFlow/nodes/FindPersonNode/FindPersonForm'
import SendInvoiceForm from 'pages/ChatFlow/nodes/SendInvoiceNode/SendInvoiceForm'
import validations from './validations'


const nodeForms = {
    message: MessageForm,
    menu: MenuForm,
    sleep: SleepForm,
    ask: AskForm,
    queue: QueueForm,
    user: UserForm,
    notify: NotifyForm,
    condition: ConditionForm,
    tag: TagForm,
    findPerson: FindPersonForm,
    sendInvoice: SendInvoiceForm,
}

const NodeConfig = ({ isOpen, node, onChange, onDelete, onClose }) => {
    const [myNode, setMyNode] = useState(node)
    useEffect(() => {
        if (['start', 'finish'].includes(node?.type)) return onClose()

        setMyNode(node)
    }, [node, onClose])

    const handleClose = () => onClose()
    const onSave = (data, helpers) =>
        onChange({ ...myNode, data: { ...myNode.data, ...data } })
    const onRemove = () => onDelete(myNode)

    const renderComponent = props => {
        const Component = nodeForms[myNode?.type]
        if (!Component) return null
        return <Component {...props} />
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth={true}>
            <Formik
                initialValues={myNode?.data}
                onSubmit={onSave}
                validationSchema={validations[myNode?.type]}
            >
                {props => (
                    <Form>
                        <DialogContent>{renderComponent(props)}</DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onRemove}
                                color="secondary"
                                style={{ marginRight: 'auto' }}>
                                Deletar
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                Cancelar
                            </Button>
                            <Button type="submit" color="primary">
                                Salvar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default NodeConfig
