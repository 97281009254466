import { Grid, TextField } from '@material-ui/core'
import { Field } from 'formik'
import { Alert } from '@material-ui/lab'

const FindPersonForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                Será enviada uma mensagem questionando o CPF do cliente e então
                com base nisso será buscada a pessoa dentro do sistema
                Apresenta.me
            </Alert>
        </Grid>
    )
}

export default FindPersonForm
