import React, { useState, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import BackupIcon from '@material-ui/icons/Backup'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import Title from '../../components/Title'

import api from '../../services/api'
import { i18n } from '../../translate/i18n'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { useHistory } from 'react-router-dom'
import ChatFlowImport from '../../components/ChatFlowImport'

const reducer = (state, action) => {
    if (action.type === 'LOAD_FLOWS') {
        const flows = action.payload
        const newFlows = []

        flows.forEach(flow => {
            const flowIndex = state.findIndex(f => f.id === flow.id)

            if (flowIndex !== -1) return (state[flowIndex] = flow)

            return newFlows.push(flow)
        })

        return [...state, ...newFlows]
    }

    if (action.type === 'DELETE_FLOW') {
        const flowToDelete = action.payload
        return state.filter(f => f.id !== flowToDelete)
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const ChatFlowList = () => {
    const router = useHistory()
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [deletingFlow, setDeletingFlow] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [toCreate, setToCreate] = useState('')
    const [chatFlowImportShow, setChatFlowImportShow] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const [flows, dispatch] = useReducer(reducer, [])

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchFlows = async () => {
                try {
                    const { data } = await api.get('/chat-flow/', {
                        filters: { smart: searchParam },
                        pageNumber,
                    })
                    dispatch({ type: 'LOAD_FLOWS', payload: data.data })
                    setLoading(false)
                } catch (err) {
                    toastError(err)
                }
            }
            fetchFlows()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, pageNumber])

    const handleAddFlow = () => {
        setCreateModalOpen(true)
    }

    const handleCreateFlow = () => {
        handleCloseCreate()
        router.push(`/chat-flow?name=${toCreate}`)
    }

    const handleCloseCreate = () => {
        setCreateModalOpen(false)
        setToCreate('')
    }

    const handleSearch = event => {
        setSearchParam(event.target.value.toLowerCase())
    }

    const handleEditFlow = flow => {
        router.push('/chat-flow/' + flow.id)
    }

    const handleDownloadFlow = flow => {
        api.get(`/chat-flow/${flow.id}/download/`).then(r => {
            const blob = new Blob([JSON.stringify(r.data)], {
                type: 'application/json;charset=utf-8;',
            })
            const linkElement = document.createElement('a')

            const url = URL.createObjectURL(blob)
            linkElement.setAttribute('href', url)
            linkElement.setAttribute('download', `${r.data.name}.json`)
            document.body.appendChild(linkElement)
            linkElement.click()
            document.body.removeChild(linkElement)
        })
    }

    const handleDuplicateFlow = flow => {
        api.post(`chat-flow/${flow.id}/duplicate`).then(r => {
            dispatch({ type: 'LOAD_FLOWS', payload: [r.data] })
        })
    }

    const handleDeleteFlow = async flowId => {
        try {
            await api.delete(`/chat-flow/${flowId}`)
            dispatch({ type: 'DELETE_FLOW', payload: flowId })
            toast.success(i18n.t('flow.toasts.deleted'))
        } catch (err) {
            toastError(err)
        }
        setDeletingFlow(null)
        setSearchParam('')
        setPageNumber(1)
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={`${i18n.t('flow.confirmationModal.deleteTitle')} ${
                    deletingFlow?.name
                }?`}
                open={deleteModalOpen}
                onClose={setDeleteModalOpen}
                onConfirm={() => handleDeleteFlow(deletingFlow.id)}>
                {i18n.t('flow.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            
            <ConfirmationModal
                title={i18n.t('flow.confirmationModal.createTitle')}
                open={createModalOpen}
                onClose={handleCloseCreate}
                onConfirm={handleCreateFlow}>
                <TextField
                    value={toCreate}
                    onChange={e => setToCreate(e.currentTarget.value)}
                    fullWidth
                    variant="outlined"
                    label="Nome para o novo ChatBot"
                />
            </ConfirmationModal>

            <ChatFlowImport
                open={chatFlowImportShow}
                onClose={() => setChatFlowImportShow(false)}
                onChange={flow =>
                    dispatch({ type: 'LOAD_FLOWS', payload: [flow] })
                }
            />
            <MainHeader>
                <Title>{i18n.t('flow.title')}</Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t('list.searchPlaceholder')}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: 'gray' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddFlow}>
                        {i18n.t('flow.buttons.add')}
                    </Button>
                    <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => setChatFlowImportShow(true)}>
                        <BackupIcon />
                    </IconButton>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t('flow.table.name')}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t('flow.table.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {flows.map(flow => (
                                <TableRow key={flow.id}>
                                    <TableCell align="center">
                                        {flow.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleDuplicateFlow(flow)
                                            }>
                                            <FileCopyIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleDownloadFlow(flow)
                                            }>
                                            <GetAppIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditFlow(flow)
                                            }>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setDeleteModalOpen(true)
                                                setDeletingFlow(flow)
                                            }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default ChatFlowList
