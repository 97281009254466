import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackdropLoading from 'components/BackdropLoading'
import api from 'services/api'
import Ticket from 'components/Ticket'
import { useTheme } from '@material-ui/styles'
import toastError from 'errors/toastError'

const OpenTicket = (...props) => {
    const theme = useTheme()
    const params = useParams()
    const [loadingPage, setLoadingPage] = useState(true)
    const [ticketId, setTicketId] = useState()

    useEffect(() => {
        api.post(`tickets/talk`, {
            number: params.number,
        }).then(r => {
            setTicketId(r.data.uuid)
            setLoadingPage(false)
        }).catch(err => {
            toastError(err)
        })
    }, [params])

    if (loadingPage)
        return (
            <BackdropLoading text="Buscando Ticket. Aguarde alguns instantes!" />
        )

    return (
        <div style={{ height: `calc(100% - ${theme.appBarHeight})` }}>
            <Ticket ticketId={ticketId} ignoreEscape />
        </div>
    )
}

export default OpenTicket
