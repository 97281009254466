import { useField } from 'formik'
import FlowSelect from '.'

const FormikFlowSelect = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    return <FlowSelect {...props} {...field} onChange={handleChange} />
}

export default FormikFlowSelect
