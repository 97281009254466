import * as Yup from 'yup'

const MessageSchema = Yup.object().shape({
    message: Yup.string().required('Required'),
})

const MenuSchema = Yup.object().shape({
    items: Yup.array()
        .of(
            Yup.object().shape({
                message: Yup.string().required('Required'),
            }),
        )
        .min(1, 'Needs at least one item.')
        .max(20, 'Too much items!'),
})

const SleepSchema = Yup.object().shape({
    time: Yup.number()
        .min(1)
        .max(60 * 60 * 10, 'Too high!'),
})

const AskSchema = Yup.object().shape({
    message: Yup.string().required('Required'),
    answer: Yup.string().nullable().required('Required'),
})

const NotifySchema = Yup.object().shape({
    number: Yup.string()
        .required('Required')
        .matches(/^\+?55? ?(\(?[0-9]{2}\)?) ?([0-9]{4,5})-?([0-9]{4})$/, 'O formato do telefone é inválido, use 55 99 99999-9999'), // Verifica número Brasileiro
    notification: Yup.string().required('Required'),
})

const ConditionSchema = Yup.object().shape({
    conditions: Yup.array()
        .of(
            Yup.object().shape({
                value: Yup.string().nullable().required('Required'),
                operator: Yup.string().required('Required'),
                comparison: Yup.string().nullable(),
            }),
        )
        .min(1, 'Needs at least one item.')
        .max(20, 'Too much items!'),
})


const validations = {
    sleep: SleepSchema,
    message: MessageSchema,
    menu: MenuSchema,
    ask: AskSchema,
    notify: NotifySchema,
    condition: ConditionSchema,
}
export default validations
