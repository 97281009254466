import React, {
    useState,
    useEffect,
    useReducer,
    useRef,
    useContext,
} from 'react'

import { isSameDay, parseISO, format } from 'date-fns'
import clsx from 'clsx'

import { green } from '@material-ui/core/colors'
import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    makeStyles,
} from '@material-ui/core'

import {
    AccessTime,
    Block,
    Done,
    DoneAll,
    ExpandMore,
    GetApp,
} from '@material-ui/icons'

import MarkdownWrapper from '../MarkdownWrapper'
import ModalImageCors from '../ModalImageCors'
import MessageOptionsMenu from '../MessageOptionsMenu'
import whatsBackground from '../../assets/wa-background.png'
import LocationPreview from '../LocationPreview'

import whatsBackgroundDark from '../../assets/wa-background-dark.png' //DARK MODE PLW DESIGN//

import api from '../../services/api'
import toastError from '../../errors/toastError'
import { SocketContext } from '../../context/Socket/SocketContext'
import { uniqBy } from 'lodash'
import VcardPreview from '../VcardPreview'

const useStyles = makeStyles(theme => ({
    messagesListWrapper: {
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        minWidth: 300,
        minHeight: 200,
    },

    messagesList: {
        backgroundImage:
            theme.mode === 'light'
                ? `url(${whatsBackground})`
                : `url(${whatsBackgroundDark})`, //DARK MODE PLW DESIGN//
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: '20px 20px 20px 20px',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },

    circleLoading: {
        color: green[500],
        position: 'absolute',
        opacity: '70%',
        top: 0,
        left: '50%',
        marginTop: 12,
    },

    messageCenter: {
        textAlign: 'center',
        marginTop: 5,
    },

    messageLeft: {
        marginRight: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        position: 'relative',
        '&:hover #messageActionsButton': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        whiteSpace: 'pre-wrap',
        backgroundColor: '#ffffff',
        color: '#303030',
        alignSelf: 'flex-start',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: '0 1px 1px #b3b3b3',
    },

    quotedContainerLeft: {
        margin: '-3px -80px 6px -6px',
        overflow: 'hidden',
        backgroundColor: '#f0f0f0',
        borderRadius: '7.5px',
        display: 'flex',
        position: 'relative',
    },

    quotedMsg: {
        padding: 10,
        maxWidth: 300,
        height: 'auto',
        display: 'block',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
    },

    quotedSideColorLeft: {
        flex: 'none',
        width: '4px',
        backgroundColor: '#6bcbef',
    },

    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: 'auto',
        display: 'block',
        position: 'relative',
        '&:hover #messageActionsButton': {
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
        },

        whiteSpace: 'pre-wrap',
        backgroundColor: '#dcf8c6',
        color: '#303030',
        alignSelf: 'flex-end',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: '0 1px 1px #b3b3b3',
    },

    quotedContainerRight: {
        margin: '-3px -80px 6px -6px',
        overflowY: 'hidden',
        backgroundColor: '#cfe9ba',
        borderRadius: '7.5px',
        display: 'flex',
        position: 'relative',
    },

    quotedMsgRight: {
        padding: 10,
        maxWidth: 300,
        height: 'auto',
        whiteSpace: 'pre-wrap',
    },

    quotedSideColorRight: {
        flex: 'none',
        width: '4px',
        backgroundColor: '#35cd96',
    },

    messageActionsButton: {
        display: 'none',
        position: 'relative',
        color: '#999',
        zIndex: 1,
        backgroundColor: 'inherit',
        opacity: '90%',
        '&:hover, &.Mui-focusVisible': { backgroundColor: 'inherit' },
    },

    messageContactName: {
        display: 'flex',
        color: '#6bcbef',
        fontWeight: 500,
    },

    textContentItem: {
        overflowWrap: 'break-word',
        padding: '3px 80px 6px 6px',
    },

    textContentItemEdited: {
        overflowWrap: 'break-word',
        padding: '3px 120px 6px 6px',
    },

    textContentItemDeleted: {
        fontStyle: 'italic',
        color: 'rgba(0, 0, 0, 0.36)',
        overflowWrap: 'break-word',
        padding: '3px 80px 6px 6px',
    },

    messageMedia: {
        objectFit: 'contain',
        width: 250,
        maxHeight: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    timestamp: {
        fontSize: 11,
        position: 'absolute',
        bottom: 0,
        right: 5,
        color: '#999',
    },

    dailyTimestamp: {
        alignItems: 'center',
        textAlign: 'center',
        alignSelf: 'center',
        width: '110px',
        backgroundColor: '#e1f3fb',
        margin: '10px',
        borderRadius: '10px',
        boxShadow: '0 1px 1px #b3b3b3',
    },

    dailyTimestampText: {
        color: '#808888',
        padding: 8,
        alignSelf: 'center',
        marginLeft: '0px',
    },

    ackIcons: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

    deletedIcon: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginRight: 4,
    },

    ackDoneAllIcon: {
        color: green[500],
        fontSize: 18,
        verticalAlign: 'middle',
        marginLeft: 4,
    },

    downloadMedia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit',
        padding: 10,
    },

    reactionLeft: {
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, .05)',
        borderRadius: 100,
        padding: 3,
        height: 28,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '95%',
        left: 15,
    },

    reactionRigth: {
        backgroundColor: 'rgb(255, 255, 255)',
        border: '1px solid rgba(0, 0, 0, .05)',
        borderRadius: 100,
        padding: 3,
        height: 28,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        top: '95%',
        right: 15,
    },
}))

const reducer = (state, action) => {
    if (action.type === 'LOAD_MESSAGES') {
        const messages = action.payload
        const newMessages = []

        messages.forEach(message => {
            const messageIndex = state.findIndex(m => m.id === message.id)
            if (messageIndex !== -1) {
                state[messageIndex] = message
            } else {
                newMessages.push(message)
            }
        })

        return [...newMessages, ...state]
    }

    if (action.type === 'ADD_MESSAGE') {
        const newMessage = action.payload
        const messageIndex = state.findIndex(m => m.id === newMessage.id)

        if (messageIndex !== -1) {
            state[messageIndex] = newMessage
        } else {
            state.push(newMessage)
        }

        return [...state]
    }

    if (action.type === 'DELETE_MESSAGE') {
        const messageToDelete = action.payload
        const messageIndex = state.findIndex(m => m.id === messageToDelete.id)

        if (messageIndex !== -1) state.splice(messageIndex, 1)

        return [...state]
    }

    if (action.type === 'UPDATE_MESSAGE') {
        const messageToUpdate = action.payload
        const messageIndex = state.findIndex(m => m.id === messageToUpdate.id)

        if (messageIndex !== -1) state[messageIndex] = messageToUpdate

        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const MessagesList = ({ ticket, ticketId, isGroup, backgroundClick }) => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)

    const [messagesList, dispatch] = useReducer(reducer, [])
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const lastMessageRef = useRef()

    const [selectedMessage, setSelectedMessage] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const messageOptionsMenuOpen = Boolean(anchorEl)
    const currentTicketId = useRef(ticketId)

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)

        currentTicketId.current = ticketId
    }, [ticketId])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchMessages = async () => {
                if (ticketId === undefined) return
                try {
                    const { data } = await api.get('/messages/' + ticketId, {
                        page: pageNumber,
                    })

                    if (currentTicketId.current === ticketId) {
                        dispatch({
                            type: 'LOAD_MESSAGES',
                            payload: data.messages,
                        })
                        setHasMore(data.hasMore)
                        setLoading(false)
                    }

                    if (pageNumber === 1 && data.messages.length > 1) {
                        scrollToBottom()
                    }
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }
            fetchMessages()
        }, 500)
        return () => {
            clearTimeout(delayDebounceFn)
        }
    }, [pageNumber, ticketId])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socketManager.onConnect(() =>
            socket.emit('joinChatBox', `${ticket.id}`),
        )

        socket.on(`company-${companyId}-appMessage`, data => {
            if (data.message.ticketId !== currentTicketId.current) return

            if (data.action === 'create') {
                dispatch({ type: 'ADD_MESSAGE', payload: data.message })
                scrollToBottom()
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_MESSAGE', payload: data.message })
                scrollToBottom()
            }

            if (data.action === 'update') {
                dispatch({ type: 'UPDATE_MESSAGE', payload: data.message })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [ticketId, ticket, socketManager])

    const loadMore = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
    }

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({})
        }
    }

    const handleScroll = e => {
        if (!hasMore) return
        const { scrollTop } = e.currentTarget

        if (scrollTop === 0) {
            document.getElementById('messagesList').scrollTop = 1
        }

        if (loading) {
            return
        }

        if (scrollTop < 50) {
            loadMore()
        }
    }

    const handleOpenMessageOptionsMenu = (e, message) => {
        setAnchorEl(e.currentTarget)
        setSelectedMessage(message)
    }

    const handleCloseMessageOptionsMenu = e => {
        setAnchorEl(null)
    }

    const checkMessageMedia = message => {
        if (
            ['locationMessage', 'liveLocationMessage'].includes(
                message.mediaType,
            ) &&
            message.body.split('|').length >= 2
        ) {
            let locationParts = message.body.split('|')
            let imageLocation = locationParts[0]
            let linkLocation = locationParts[1]

            let descriptionLocation = ''

            if (locationParts.length > 2)
                descriptionLocation = message.body.split('|')[2]

            return (
                <LocationPreview
                    image={imageLocation}
                    link={linkLocation}
                    description={descriptionLocation}
                />
            )
        } else if (message.mediaType === 'image') {
            return <ModalImageCors imageUrl={message.mediaUrl} />
        } else if (message.mediaType === 'audio') {
            return (
                <audio controls>
                    <source src={message.mediaUrl} type="audio/ogg"></source>
                </audio>
            )
        } else if (message.mediaType === 'video') {
            return (
                <video
                    className={classes.messageMedia}
                    src={message.mediaUrl}
                    controls
                />
            )
        } else if (
            ['contactMessage', 'contactsArrayMessage'].includes(
                message.mediaType,
            )
        ) {
            return <VcardPreview vcard={message.body} />
        } else {
            return (
                <>
                    <div className={classes.downloadMedia}>
                        <Button
                            startIcon={<GetApp />}
                            color="primary"
                            variant="outlined"
                            target="_blank"
                            href={message.mediaUrl}>
                            Download
                        </Button>
                    </div>
                    <Divider />
                </>
            )
        }
    }

    const renderMessageAck = message => {
        if (message.ack === 1) {
            return <AccessTime fontSize="small" className={classes.ackIcons} />
        }
        if (message.ack === 2) {
            return <Done fontSize="small" className={classes.ackIcons} />
        }
        if (message.ack === 3) {
            return <DoneAll fontSize="small" className={classes.ackIcons} />
        }
        if (message.ack === 4 || message.ack === 5) {
            return (
                <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />
            )
        }
    }

    const renderDailyTimestamps = (message, index) => {
        if (index === 0) {
            return (
                <span
                    className={classes.dailyTimestamp}
                    key={`timestamp-${message.id}`}>
                    <div className={classes.dailyTimestampText}>
                        {format(
                            parseISO(messagesList[index].createdAt),
                            'dd/MM/yyyy',
                        )}
                    </div>
                </span>
            )
        }
        if (index < messagesList.length - 1) {
            let messageDay = parseISO(messagesList[index].createdAt)
            let previousMessageDay = parseISO(messagesList[index - 1].createdAt)

            if (!isSameDay(messageDay, previousMessageDay)) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        key={`timestamp-${message.id}`}>
                        <div className={classes.dailyTimestampText}>
                            {format(
                                parseISO(messagesList[index].createdAt),
                                'dd/MM/yyyy',
                            )}
                        </div>
                    </span>
                )
            }
        }
        if (index === messagesList.length - 1) {
            return (
                <div
                    key={`ref-${message.createdAt}`}
                    ref={lastMessageRef}
                    style={{ float: 'left', clear: 'both' }}
                />
            )
        }
    }

    const renderNumberTicket = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageTicket = message.ticketId
            let previousMessageTicket = messagesList[index - 1].ticketId

            if (messageTicket !== previousMessageTicket) {
                return (
                    <span
                        className={classes.dailyTimestamp}
                        key={`ticket-${message.id}`}
                        style={{ width: 'auto' }}>
                        <div className={classes.dailyTimestampText}>
                            Atendimento #{message.ticketId}{' '}
                            {format(
                                parseISO(message.createdAt),
                                'dd/MM/yyyy HH:mm:ss',
                            )}
                        </div>
                    </span>
                )
            }
        }
    }

    const renderMessageDivider = (message, index) => {
        if (index < messagesList.length && index > 0) {
            let messageUser = messagesList[index].fromMe
            let previousMessageUser = messagesList[index - 1].fromMe

            if (messageUser !== previousMessageUser) {
                return (
                    <span
                        style={{ marginTop: 16 }}
                        key={`divider-${message.id}`}></span>
                )
            }
        }
    }

    const renderQuotedMessage = message => {
        return (
            <div
                className={clsx(classes.quotedContainerLeft, {
                    [classes.quotedContainerRight]: message.fromMe,
                })}>
                <span
                    className={clsx(classes.quotedSideColorLeft, {
                        [classes.quotedSideColorRight]:
                            message.quotedMsg?.fromMe,
                    })}></span>
                <div className={classes.quotedMsg}>
                    {!message.quotedMsg?.fromMe && (
                        <span className={classes.messageContactName}>
                            {message.quotedMsg?.contact?.name}
                        </span>
                    )}

                    {message.quotedMsg.mediaType === 'audio' && (
                        <div className={classes.downloadMedia}>
                            <audio controls>
                                <source
                                    src={message.quotedMsg.mediaUrl}
                                    type="audio/ogg"></source>
                            </audio>
                        </div>
                    )}
                    {message.quotedMsg.mediaType === 'video' && (
                        <video
                            className={classes.messageMedia}
                            src={message.quotedMsg.mediaUrl}
                            controls
                        />
                    )}
                    {message.quotedMsg.mediaType === 'application' && (
                        <div className={classes.downloadMedia}>
                            <Button
                                startIcon={<GetApp />}
                                color="primary"
                                variant="outlined"
                                target="_blank"
                                href={message.quotedMsg.mediaUrl}>
                                Download
                            </Button>
                        </div>
                    )}

                    {(message.quotedMsg.mediaType === 'image' && (
                        <ModalImageCors imageUrl={message.quotedMsg.mediaUrl} />
                    )) ||
                        message.quotedMsg?.body}
                </div>
            </div>
        )
    }

    const renderReaction = message => {
        const reacts = messagesList.filter(
            react =>
                react.mediaType === 'reactionMessage' &&
                react.quotedMsgId === message.id,
        )

        const reactRender = uniqBy(reacts, 'body').map(react => {
            return <>{react.body}</>
        })

        const styleClass = !message.fromMe
            ? classes.reactionLeft
            : classes.reactionRigth
        return (
            reacts.length > 0 && <div className={styleClass}>{reactRender}</div>
        )
    }

    const renderMessages = () => {
        if (messagesList.length > 0) {
            const viewMessagesList = messagesList
                .filter(message => message.mediaType !== 'reactionMessage')
                .map((message, index) => {
                    if (message.mediaType === 'call_log') {
                        return (
                            <React.Fragment key={message.id}>
                                {renderDailyTimestamps(message, index)}
                                {renderNumberTicket(message, index)}
                                {renderMessageDivider(message, index)}
                                <div className={classes.messageCenter}>
                                    <IconButton
                                        variant="contained"
                                        size="small"
                                        id="messageActionsButton"
                                        disabled={message.isDeleted}
                                        className={classes.messageActionsButton}
                                        onClick={e =>
                                            handleOpenMessageOptionsMenu(
                                                e,
                                                message,
                                            )
                                        }>
                                        <ExpandMore />
                                    </IconButton>
                                    {isGroup && (
                                        <span
                                            className={
                                                classes.messageContactName
                                            }>
                                            {message.contact?.name}
                                        </span>
                                    )}
                                    <div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 17"
                                            width="20"
                                            height="17">
                                            <path
                                                fill="#df3333"
                                                d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                                        </svg>{' '}
                                        <span>
                                            Chamada de voz/vídeo perdida às{' '}
                                            {format(
                                                parseISO(message.createdAt),
                                                'HH:mm',
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }

                    const hasReaction = !!messagesList.find(
                        reaction => reaction.quotedMsgId === message.id,
                    )

                    if (!message.fromMe) {
                        return (
                            <React.Fragment key={message.id}>
                                {renderDailyTimestamps(message, index)}
                                {renderNumberTicket(message, index)}
                                {renderMessageDivider(message, index)}
                                <div
                                    className={classes.messageLeft}
                                    style={{
                                        marginBottom: hasReaction
                                            ? 25
                                            : 'unset',
                                    }}>
                                    <IconButton
                                        variant="contained"
                                        size="small"
                                        id="messageActionsButton"
                                        disabled={message.isDeleted}
                                        className={classes.messageActionsButton}
                                        onClick={e =>
                                            handleOpenMessageOptionsMenu(
                                                e,
                                                message,
                                            )
                                        }>
                                        <ExpandMore />
                                    </IconButton>
                                    {isGroup && (
                                        <span
                                            className={
                                                classes.messageContactName
                                            }>
                                            {message.contact?.name}
                                        </span>
                                    )}

                                    {/* aviso de mensagem apagado pelo contato */}
                                    {message.isDeleted && (
                                        <div>
                                            <span className={'message-deleted'}>
                                                Essa mensagem foi apagada pelo
                                                contato &nbsp;
                                                <Block
                                                    color="error"
                                                    fontSize="small"
                                                    className={
                                                        classes.deletedIcon
                                                    }
                                                />
                                            </span>
                                        </div>
                                    )}

                                    {(message.mediaUrl ||
                                        [
                                            'contactMessage',
                                            'locationMessage',
                                            'liveLocationMessage',
                                            'contactsArrayMessage',
                                        ].includes(message.mediaType)) &&
                                        checkMessageMedia(message)}

                                    <div className={classes.textContentItem}>
                                        {message.quotedMsg &&
                                            renderQuotedMessage(message)}
                                        <MarkdownWrapper>
                                            {[
                                                'contactMessage',
                                                'locationMessage',
                                                'liveLocationMessage',
                                                'contactsArrayMessage',
                                            ].includes(message.mediaType)
                                                ? null
                                                : message.body}
                                        </MarkdownWrapper>
                                        <span className={classes.timestamp}>
                                            {message.isEdited && (
                                                <span>Editada </span>
                                            )}
                                            {format(
                                                parseISO(message.createdAt),
                                                'HH:mm',
                                            )}
                                        </span>
                                        {renderReaction(message)}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={message.id}>
                                {renderDailyTimestamps(message, index)}
                                {renderNumberTicket(message, index)}
                                {renderMessageDivider(message, index)}
                                <div
                                    className={classes.messageRight}
                                    style={{
                                        marginBottom: hasReaction
                                            ? 25
                                            : 'unset',
                                    }}>
                                    <IconButton
                                        variant="contained"
                                        size="small"
                                        id="messageActionsButton"
                                        disabled={message.isDeleted}
                                        className={classes.messageActionsButton}
                                        onClick={e =>
                                            handleOpenMessageOptionsMenu(
                                                e,
                                                message,
                                            )
                                        }>
                                        <ExpandMore />
                                    </IconButton>

                                    {(message.mediaUrl ||
                                        [
                                            'contactMessage',
                                            'locationMessage',
                                            'liveLocationMessage',
                                            'contactsArrayMessage',
                                        ].includes(message.mediaType)) &&
                                        checkMessageMedia(message)}

                                    <div
                                        className={clsx(
                                            classes.textContentItem,
                                            {
                                                [classes.textContentItemDeleted]:
                                                    message.isDeleted,
                                                [classes.textContentItemEdited]:
                                                    message.isEdited,
                                            },
                                        )}>
                                        {message.isDeleted && (
                                            <Block
                                                color="disabled"
                                                fontSize="small"
                                                className={classes.deletedIcon}
                                            />
                                        )}

                                        {message.quotedMsg &&
                                            renderQuotedMessage(message)}

                                        <MarkdownWrapper>
                                            {[
                                                'contactMessage',
                                                'locationMessage',
                                                'liveLocationMessage',
                                                'contactsArrayMessage',
                                            ].includes(message.mediaType)
                                                ? null
                                                : message.body}
                                        </MarkdownWrapper>
                                        <span className={classes.timestamp}>
                                            {message.isEdited && (
                                                <span>Editada </span>
                                            )}
                                            {format(
                                                parseISO(message.createdAt),
                                                'HH:mm',
                                            )}
                                            {renderMessageAck(message)}
                                        </span>
                                        {renderReaction(message)}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }
                })
            return viewMessagesList
        } else {
            return <div>Diga olá para seu novo contato!</div>
        }
    }

    return (
        <div className={classes.messagesListWrapper}>
            <MessageOptionsMenu
                message={selectedMessage}
                anchorEl={anchorEl}
                menuOpen={messageOptionsMenuOpen}
                handleClose={handleCloseMessageOptionsMenu}
            />
            <div
                id="messagesList"
                className={classes.messagesList}
                onClick={backgroundClick}
                onScroll={handleScroll}>
                {messagesList.length > 0 ? renderMessages() : []}
            </div>
            {loading && (
                <div>
                    <CircularProgress className={classes.circleLoading} />
                </div>
            )}
        </div>
    )
}

export default MessagesList
