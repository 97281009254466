import React, { useEffect, useState } from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import { i18n } from '../../translate/i18n'
import OutlinedDiv from '../OutlinedDiv'
import api from '../../services/api'

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.apre.dark,
    },
    custom: {
        backgroundColor: theme.palette.apre.main,
    },
    added: {
        backgroundColor: theme.palette.apre.light,
    },
}))

const defaultVariables = [
    {
        name: i18n.t('messageVariablesPicker.vars.contactFirstName'),
        value: '{{firstName}}',
    },
    {
        name: i18n.t('messageVariablesPicker.vars.contactName'),
        value: '{{name}}',
    },
    {
        name: i18n.t('messageVariablesPicker.vars.greeting'),
        value: '{{ms}}',
    },
    {
        name: i18n.t('messageVariablesPicker.vars.protocolNumber'),
        value: '{{protocol}}',
    },
    {
        name: i18n.t('messageVariablesPicker.vars.date'),
        value: '{{date}}',
    },
    {
        name: i18n.t('messageVariablesPicker.vars.hour'),
        value: '{{hour}}',
    },
]

const MessageVariablesPicker = ({ onClick, disabled, customVariables }) => {
    const classes = useStyles()
    const [variables, setVariables] = useState(defaultVariables)

    useEffect(() => {
        if (customVariables?.length) {
            if (customVariables.filter(v => !v.name || !v.value).length)
                throw new Error("customVariable needs to have name and value");

            setVariables(old => [
                ...old,
                ...customVariables.map(v => ({
                    name: v.name,
                    value: !v.value.startsWith('{{') && !v.value.endsWith('}}') ? `{{${v.value}}}` : v.value,
                    added: true
                })),
            ])
        }
    }, [customVariables])

    const handleClick = (e, value) => {
        e.preventDefault()
        if (disabled) return
        onClick(value)
    }

    useEffect(() => {
        api.get('/custom-field')
            .then(r => {
                const addVariables = r.data.customFields.map(field => ({
                    name: field.name,
                    value: `{{custom ${field.name}}}`,
                    custom: true,
                }))

                setVariables(old => [...old, ...addVariables])
            })
            .catch(err => console.error(err))
    }, [])

    return (
        <OutlinedDiv
            margin="dense"
            fullWidth
            label={i18n.t('messageVariablesPicker.label')}
            disabled={disabled}>
            {variables.map(variable => (
                <Chip
                    key={variable.value}
                    onMouseDown={e => handleClick(e, variable.value)}
                    label={variable.name}
                    size="small"
                    className={[
                        classes.chip,
                        variable.custom ? classes.custom : null,
                        variable.added ? classes.added : null,
                    ].join(' ')}
                />
            ))}
        </OutlinedDiv>
    )
}

export default MessageVariablesPicker
