import { Grid, TextField } from '@material-ui/core'
import { Field } from 'formik'
import MessageWithPicker from '../../components/MessageWithPicker'
import { Alert } from '@material-ui/lab'

const NotifyForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    error={props.touched?.title && props.errors?.title}
                    helperText={props.touched?.title && props.errors?.title}
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={TextField}
                    name="number"
                    label="Número"
                    fullWidth
                    variant="outlined"
                    error={props.touched?.number && props.errors?.number}
                    helperText={props.touched?.number && props.errors?.number}
                    {...props.getFieldProps('number')}
                />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="notification"
                    label="Mensagem"
                    error={props.touched?.notification && props.errors?.notification}
                    helperText={props.touched?.notification && props.errors?.notification}
                    {...props.getFieldProps(`notification`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                A mensagem configurada aqui será enviada para o número informado
                e não para o contato que está sendo atendido pelo Bot.
            </Alert>
        </Grid>
    )
}

export default NotifyForm
