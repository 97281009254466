import { Handle, Position } from 'reactflow';
import CustomNodeBase from '../../components/CustomNodeBase'
import DoneIcon from '@material-ui/icons/Done'

function FinishNode(myNode) {
    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                canEdit={false}
                name="Fechar Ticket"
                icon={DoneIcon}
            />
        </div>
    )
}

export default FinishNode;
