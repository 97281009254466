import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { Field } from 'formik'
import FormikCustomFieldAutocomplete from 'components/CustomFieldAutocomplete/FormikCustomFieldAutocomplete'
import { useCallback } from 'react'
import { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'

const useStyle = makeStyles(theme => ({
    conditionFields: {
        height: '100%',
        width: '100%',
        minWidth: 'auto',
    },

    divider: {
        borderStyle: 'dashed',
        borderColor: '#b9b9b9',
        marginBlock: '0.75rem',
    },
}))

const RenderCondition = ({ onRemove, item, ...props }) => {
    const classes = useStyle()

    const operators = [
        { value: 'equal', label: 'Igual', types: ['text', 'customField'] },
        {
            value: 'not_equal',
            label: 'Diferente',
            types: ['text', 'customField'],
        },
        { value: 'contains', label: 'Contém', types: ['text'] },
        { value: 'not_contains', label: 'Não contém', types: ['text'] },
        { value: 'empty', label: 'Vazio', types: ['text'] },
        { value: 'not_empty', label: 'Não vazio', types: ['text'] },
        { value: 'starts', label: 'Inicia com', types: ['text'] },
        { value: 'not_starts', label: 'Não inicia com', types: ['text'] },
        { value: 'ends', label: 'Finaliza com', types: ['text'] },
        { value: 'not_ends', label: 'Não finaliza com', types: ['text'] },
        { value: 'regex', label: 'Regex', types: ['text'] },
    ]

    const handleTypeChange = () => {
        props.setFieldValue(`conditions[${item}].comparison`, '')

        const type = props.values.conditions[item].type
        if (type === 'customField')
            return props.setFieldValue(`conditions[${item}].type`, 'text')

        return props.setFieldValue(`conditions[${item}].type`, 'customField')
    }

    const handleFocus = (field) => {
        let conditions = props.touched.conditions
        if (!conditions?.length) conditions = []

        conditions[item] = {
            ...conditions[item],
            [field]: true,
        }

        props.setTouched({ ...props.touched, conditions })
    }

    const getTouchError = (type, field) => {
        return props[type].conditions?.[item]?.[field]
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <Field
                    onFocus={() => handleFocus('value')}
                    as={FormikCustomFieldAutocomplete}
                    name={`conditions[${item}].value`}
                    label="Variável"
                    className={classes.conditionFields}
                    disableClearable={true}
                    {...props.getFieldProps(`conditions[${item}].value`)}
                    error={
                        Boolean(getTouchError('touched', 'value') &&
                        getTouchError('errors', 'value'))
                    }
                    helperText={
                        getTouchError('touched', 'value') &&
                        getTouchError('errors', 'value')
                    }
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <FormControl
                    onFocus={() => handleFocus('operator')}
                    error={
                        getTouchError('touched', 'operator') &&
                        getTouchError('errors', 'operator')
                    }>
                    <Select
                        labelId={`conditions[${item}]-label`}
                        value={props.values.conditions[item].operator}
                        variant="outlined"
                        defaultValue="equal"
                        className={classes.conditionFields}
                        onChange={e => {
                            props.setFieldValue(
                                `conditions[${item}].operator`,
                                e.target.value,
                            )
                        }}>
                        {operators
                            .filter(operator =>
                                operator.types.includes(
                                    props.values.conditions[item].type,
                                ),
                            )
                            .map(operator => (
                                <MenuItem value={operator.value}>
                                    {operator.label}
                                </MenuItem>
                            ))}
                    </Select>

                    {getTouchError('touched', 'operator') && (
                        <FormHelperText>
                            {getTouchError('errors', 'operator')}
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
                {!['empty', 'not_empty'].includes(
                    props.values.conditions[item].operator,
                ) && (
                    <>
                        {props.values.conditions[item].type ===
                        'customField' ? (
                            <Field
                                onFocus={() => handleFocus('comparison')}
                                as={FormikCustomFieldAutocomplete}
                                name={`conditions[${item}].comparison`}
                                label="Comparação"
                                onChange={(e, value) => {
                                    props.setFieldValue(
                                        `conditions[${item}].comparison`,
                                        value,
                                    )
                                }}
                                className={classes.conditionFields}
                                {...props.getFieldProps(
                                    `conditions[${item}].comparison`,
                                )}
                                error={
                                    getTouchError('touched', 'comparison') &&
                                    getTouchError('errors', 'comparison')
                                }
                                helperText={
                                    getTouchError('touched', 'comparison') &&
                                    getTouchError('errors', 'comparison')
                                }
                            />
                        ) : (
                            <Field
                                onFocus={() => handleFocus('comparison')}
                                as={TextField}
                                name={`conditions[${item}].comparison`}
                                label="Comparação"
                                variant="outlined"
                                onChange={e => {
                                    props.setFieldValue(
                                        `conditions[${item}].comparison`,
                                        e.currentTarget.value,
                                    )
                                }}
                                className={classes.conditionFields}
                                {...props.getFieldProps(
                                    `conditions[${item}].comparison`,
                                )}
                                error={
                                    getTouchError('touched', 'comparison') &&
                                    getTouchError('errors', 'comparison')
                                }
                                helperText={
                                    getTouchError('touched', 'comparison') &&
                                    getTouchError('errors', 'comparison')
                                }
                            />
                        )}
                    </>
                )}
            </Grid>

            <Grid item xs={6} md={1}>
                {['equal', 'not_equal'].includes(
                    props.values.conditions[item].operator,
                ) && (
                    <Button
                        onClick={handleTypeChange}
                        className={classes.conditionFields}
                        title="Trocar o tipo de comparação">
                        <SyncAltIcon />
                    </Button>
                )}
            </Grid>

            <Grid item xs={6} md={1}>
                <Button onClick={onRemove} className={classes.conditionFields}>
                    <DeleteIcon />
                </Button>
            </Grid>
        </Grid>
    )
}

const ConditionForm = props => {
    const classes = useStyle()

    const addCondition = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues.conditions?.length) newValues.conditions = []
        newValues.conditions.push({
            id: uuid(),
            operator: 'equal',
            type: 'text',
            value: null,
        })

        props.setValues(newValues)
    }, [props])

    const removeCondition = index => {
        const conditions = props.touched.conditions
        conditions.splice(index, 1)
        props.setTouched({ ...props.touched, conditions })

        const newValues = cloneDeep(props.values)
        newValues.conditions.splice(index, 1) // Criado clone para não alterar o data por referencia

        props.setValues(newValues)
    }

    return (
        <div>
            <Field
                as={TextField}
                variant="outlined"
                fullWidth
                name="title"
                label="Título"
                {...props.getFieldProps(`title`)}
                style={{ marginBottom: 10 }}
            />

            <Button onClick={addCondition} variant="contained">
                Adicionar Condição
            </Button>

            {props.values.conditions?.map((_, index) => {
                return (
                    <>
                        <hr className={classes.divider} />
                        <RenderCondition
                            item={index}
                            key={index}
                            onRemove={() => removeCondition(index)}
                            {...props}
                        />
                    </>
                )
            })}
        </div>
    )
}

export default ConditionForm
