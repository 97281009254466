import api from '../../services/api'

const useCompanies = () => {
    const save = async data => {
        const { data: responseData } = await api.post('/companies', data)
        return responseData
    }

    const findAll = async () => {
        const { data } = await api.get(`/companies`)
        return data
    }

    const find = async id => {
        const { data } = await api.get(`/companies/${id}`)
        return data
    }

    const list = async () => {
        const { data } = (await api.get(`/companies`, {
            sort: 'name',
            include: {
                plan: 'id,name,value',
                settings: '*'
            },
        })).data
        return data
    }

    const update = async data => {
        const { data: responseData } = await api.put(
            `/companies/${data.id}`,
            data,
        )
        return responseData
    }

    const remove = async id => {
        const { data } = await api.delete(`/companies/${id}`)
        return data
    }

    return {
        save,
        update,
        remove,
        find,
        list,
        findAll,
    }
}

export default useCompanies
