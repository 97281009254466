import { makeStyles } from '@material-ui/core'
import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import mime from 'mime'
import MarkdownWrapper from 'components/MarkdownWrapper'
import CustomNodeBase from '../../components/CustomNodeBase'
import MessageIcon from '@material-ui/icons/Message'

const useStyles = makeStyles(theme => ({
    media: {
        width: 200,
        maxHeight: 200,
        objectFit: 'contain'
    },
    cantPreview: {
        fontSize: '0.8em',
        fontStyle: 'italic',
    }
}))

function RenderMedia({ media }) {
    const classes = useStyles()
    const filename = media.originalname || media.filename || media.mediaPath
    const url = media.blob || media.mediaUrl

    if (!filename) return null

    const type = mime.getType(filename).split('/')[0]

    switch (type) {
        case 'image':
            return <img className={classes.media} src={url} alt={filename} />
        case 'video':
            return <video className={classes.media} src={url} controls />
        case 'audio':
            return <audio className={classes.media} src={url} controls />
        default:
            return (
                <span className={classes.cantPreview} title={`${filename} - ${type}`}>
                    Não é possível mostrar preview deste arquivo! <a href={url} target="_blank" rel="noreferrer" download={filename}>Baixar</a>
                </span>
            )
    }
}

function MessageNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase node={myNode} name="Mensagem" icon={MessageIcon}>
                <MarkdownWrapper>
                    {data.message || 'Clique para definir uma mensagem!'}
                </MarkdownWrapper>
                <RenderMedia
                    media={
                        data.file || {
                            mediaPath: data.mediaPath,
                            mediaUrl: data.mediaUrl,
                        }
                    }
                />
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default MessageNode
