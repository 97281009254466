import { Field, useField } from "formik"
import { useRef } from "react"
import MessageInput from "../MessageInput"
import MessageVariablesPicker from "components/MessageVariablesPicker"

const MessageWithPicker = ({name, label, customVariables, ...props}) => {
    const [, , helpers] = useField(name)
    const inputRef = useRef()

    const handleClickMsgVar = value => {
        const el = inputRef.current
        const firstHalfText = el.value.substring(0, el.selectionStart)
        const secondHalfText = el.value.substring(el.selectionEnd)
        const newCursorPos = el.selectionStart + value.length

        helpers.setValue(`${firstHalfText}${value}${secondHalfText}`)
        setTimeout(() => {
            inputRef.current.setSelectionRange(newCursorPos, newCursorPos)
        }, 100)
    }

    return (
        <>
            <Field
                as={MessageInput}
                name={name}
                label={label}
                fullWidth
                innerRef={inputRef}
                {...props}
            />

            <MessageVariablesPicker
                onClick={value => handleClickMsgVar(value)}
                customVariables={customVariables}
            />
        </>
    )
}

export default MessageWithPicker
