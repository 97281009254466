import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import TimerIcon from '@material-ui/icons/Timer'

function SleepNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase node={myNode} name="Espera" icon={TimerIcon}>
                {data.time
                    ? data.time + ' Segundos'
                    : 'Clique para definir o tempo!'}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default SleepNode
