import { useField } from 'formik'
import TagAutocomplete from '.'

const FormikTagAutocomplete = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    return (
        <TagAutocomplete
            {...field}
            {...props}
            onChange={handleChange}
        />
    )
}

export default FormikTagAutocomplete
