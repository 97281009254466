import React, { useEffect, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { i18n } from '../../translate/i18n'

const FlowSelect = ({
    value,
    onChange,
    multiple = false,
    label = i18n.t('flow.title'),
}) => {
    const [flows, setFlows] = useState([])

    useEffect(() => {
        fetchFlows()
    }, [])

    const fetchFlows = async () => {
        try {
            const { data } = await api.get('/chat-flow')
            setFlows(data.data)
        } catch (err) {
            toastError(err)
        }
    }

    const handleChange = e => {
        onChange(e.target.value)
    }

    return (
        <div>
            <FormControl fullWidth margin="dense" variant="outlined">
                <InputLabel shrink={value ? true : false}>{label}</InputLabel>
                <Select
                    label={label}
                    multiple={multiple}
                    labelWidth={60}
                    value={value || ''}
                    onChange={handleChange}
                    renderValue={selected => {
                        const flowsToShow = flows.filter(
                            flow =>
                                flow.id === selected ||
                                (Array.isArray(selected) &&
                                    selected.includes(flow.id)),
                        )
                        return flowsToShow.map(flow => flow.name).join(', ')
                    }}>
                    {!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
                    {flows.map(flow => (
                        <MenuItem key={flow.id} value={flow.id}>
                            {flow.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
}

export default FlowSelect
