import api from "../../services/api"

const useMediaUpload = () => {
    const uploadFile = async file => {
        if (!(file instanceof File))
            throw new Error("file needs to be a File instance");

        const formData = new FormData()
        formData.append('file', file)

        const uploadedFile = (await api.post(`/upload`, formData))?.data

        const byteCharacters = atob(uploadedFile.binary)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: uploadedFile.mimetype })

        const blobUrl = URL.createObjectURL(blob)
        delete uploadedFile.binary

        return { ...uploadedFile, blob: blobUrl }
    }

    return {
        uploadFile
    }
}

export default useMediaUpload
