import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

function NotifyNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Notificação"
                icon={NotificationsActiveIcon}>
                <div>{data.number || 'Clique para configurar!'}</div>
                <div>{data.notification}</div>
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default NotifyNode
