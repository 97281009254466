import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import MarkdownWrapper from 'components/MarkdownWrapper'

function SendInvoiceNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Último boleto"
                icon={MonetizationOnIcon}>
                <MarkdownWrapper>
                    {data.message || 'Clique para definir uma mensagem!'}
                </MarkdownWrapper>
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default SendInvoiceNode
