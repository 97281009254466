import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { DeviceHubOutlined } from '@material-ui/icons';
import usePlans from "../hooks/usePlans";


function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button dense component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);


  const { getPlanCompany } = usePlans();
 
  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(companyId);

      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
      <div onClick={drawerClose}>
          <Can
              role={user.profile}
              perform="dashboard:view"
              yes={() => (
                  <ListItemLink
                      to="/"
                      primary="Dashboard"
                      icon={<DashboardOutlinedIcon />}
                  />
              )}
          />

          <ListItemLink
              to="/tickets"
              primary={i18n.t('mainDrawer.listItems.tickets')}
              icon={<WhatsAppIcon />}
          />

          <ListItemLink
              to="/quick-messages"
              primary={i18n.t('mainDrawer.listItems.quickMessages')}
              icon={<FlashOnIcon />}
          />

          <ListItemLink
              to="/contacts"
              primary={i18n.t('mainDrawer.listItems.contacts')}
              icon={<ContactPhoneOutlinedIcon />}
          />

          <ListItemLink
              to="/tags"
              primary={i18n.t('mainDrawer.listItems.tags')}
              icon={<LocalOfferIcon />}
          />

          <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                  <>
                      <Divider />
                      <ListSubheader
                          hidden={collapsed}
                          style={{
                              position: 'relative',
                              fontSize: '17px',
                              textAlign: 'left',
                              paddingLeft: 20
                          }}
                          inset
                          color="inherit">
                          {i18n.t('mainDrawer.listItems.administration')}
                      </ListSubheader>

                      <ListItemLink
                          to="/custom-field"
                          primary={i18n.t('mainDrawer.listItems.customField')}
                          icon={<LibraryAddIcon />}
                      />

                      {showIntegrations && (
                          <ListItemLink
                              to="/queue-integration"
                              primary={i18n.t(
                                  'mainDrawer.listItems.queueIntegration'
                              )}
                              icon={<DeviceHubOutlined />}
                          />
                      )}
                      <ListItemLink
                          to="/connections"
                          primary={i18n.t('mainDrawer.listItems.connections')}
                          icon={
                              <Badge
                                  badgeContent={connectionWarning ? '!' : 0}
                                  color="error">
                                  <SyncAltIcon />
                              </Badge>
                          }
                      />
                      <ListItemLink
                          to="/queues"
                          primary={i18n.t('mainDrawer.listItems.queues')}
                          icon={<AccountTreeOutlinedIcon />}
                      />
                      <ListItemLink
                          to="/chat-flows"
                          primary={i18n.t('mainDrawer.listItems.chatFlow')}
                          icon={<LinearScaleIcon />}
                      />
                      <ListItemLink
                          to="/users"
                          primary={i18n.t('mainDrawer.listItems.users')}
                          icon={<PeopleAltOutlinedIcon />}
                      />
                      {showExternalApi && (
                          <>
                              <ListItemLink
                                  to="/messages-api"
                                  primary={i18n.t(
                                      'mainDrawer.listItems.messagesAPI'
                                  )}
                                  icon={<CodeRoundedIcon />}
                              />
                          </>
                      )}

                      <ListItemLink
                          to="/settings"
                          primary={i18n.t('mainDrawer.listItems.settings')}
                          icon={<SettingsOutlinedIcon />}
                      />
                  </>
              )}
          />
      </div>
  )
};

export default MainListItems;
