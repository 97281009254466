import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
	userQueues,
	selectedQueueIds = [],
	onChange,
}) => {
    const [checkedAll, setCheckedAll] = useState(false)
    
    useEffect(() => {
        const queuesIds = userQueues.map(q => q.id)
        const difference = queuesIds.filter(queues => !selectedQueueIds.includes(queues))

        if (difference.length) return setCheckedAll(false)

        return setCheckedAll(true)
    }, [userQueues, selectedQueueIds])

	const handleChange = (e) => {
        const values = e.target.value
        if (values.includes('Select all')) return

		onChange(values.filter(r => r !== 'Select all'))
	};

    const selectAll = () => {
        if (checkedAll) onChange([])
        else onChange(userQueues.map(q => q.id))
    }

	return (
        <div style={{ width: 120, marginTop: -4 }}>
            <FormControl fullWidth margin="dense">
                <Select
                    multiple
                    displayEmpty
                    variant="outlined"
                    value={selectedQueueIds}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={() =>
                        i18n.t('ticketsQueueSelect.placeholder')
                    }>
                    <MenuItem dense value="Select all" onClick={selectAll}>
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={checkedAll}
                        />
                        <ListItemText
                            primary={checkedAll ? i18n.t('ticketsQueueSelect.selectAll.none') : i18n.t('ticketsQueueSelect.selectAll.all')}
                        />
                    </MenuItem>

                    {userQueues?.length > 0 &&
                        userQueues.map(queue => (
                            <MenuItem dense key={queue.id} value={queue.id}>
                                <Checkbox
                                    style={{
                                        color: queue.color,
                                    }}
                                    size="small"
                                    color="primary"
                                    checked={
                                        selectedQueueIds.indexOf(queue.id) > -1
                                    }
                                />
                                <ListItemText primary={queue.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default TicketsQueueSelect;
