import { useField } from 'formik'
import CustomFieldAutocomplete from '.'

const FormikCustomFieldAutocomplete = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        if (!props.onInputChange)
            helpers.setValue(value)
    }

    return (
        <CustomFieldAutocomplete
            {...field}
            {...props}
            onChange={handleChange}
        />
    )
}

export default FormikCustomFieldAutocomplete
