import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../services/api'
import { i18n } from '../../translate/i18n'

export default function CustomFieldAutocomplete({
    value,
    onChange,
    multiple = false,
    label = i18n.t('customField.title'),
    freeSolo,
    disableClearable,
    ...props
}) {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState([])
    const loading = open && options.length === 0

    useEffect(() => {
        const getLabel = async fieldId => {
            if (!fieldId) return

            return (await api.get(`/custom-field/${fieldId}`)).data
        }

        if (multiple)
            return new Promise(async resolve => {
                Promise.all(value.forEach(val => getLabel(val))).then(r => {
                    debugger
                })
            })

        return getLabel(value).then(label => {
            setLabelOptions(label)
        })
    }, [value, multiple])

    useEffect(() => {
        let active = true

        if (!loading) return undefined

        api.get('/custom-field').then(r => {
            if (active) {
                setOptions(r.data.customFields)
            }
        })

        return () => (active = false)
    }, [loading])

    useEffect(() => {
        if (!open) setOptions([])
    }, [open])

    const handleChange = (e, value) => {
        setLabelOptions(value)

        if (!value)
            return onChange(null)

        onChange(value.id)
    }

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value}
            getOptionLabel={option => option.name || option}
            options={options}
            loading={loading}
            autoSelect={true}
            onChange={handleChange}
            multiple={multiple}
            value={labelOptions}
            freeSolo={freeSolo}
            disableClearable={disableClearable}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    {...props}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
