import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import RateReviewIcon from '@material-ui/icons/RateReview'

function AskNode(myNode) {
    const { data, ...props } = myNode

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase node={myNode} name="Pergunta" icon={RateReviewIcon}>
                {data.message ? (
                    <MarkdownWrapper>{data.message}</MarkdownWrapper>
                ) : (
                    'Clique para configurar!'
                )}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default AskNode
