import { Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

function StartNode(myNode) {
    return (
        <div>
            <CustomNodeBase
                node={myNode}
                noOptions={true}
                name="Início"
                icon={<PlayCircleOutlineIcon />}
            />
            <CustomStartHandle position={Position.Bottom} nodeId={myNode.id} />
        </div>
    )
}

export default StartNode
