import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import useMediaUpload from '../../hooks/useMediaUpload'
import { toast } from 'react-toastify'
import { Alert } from '@material-ui/lab'

const ChatFlowImport = ({ open, onClose, onChange, ...props }) => {
    const { uploadFile } = useMediaUpload()
    const [flow, setFlow] = useState({})
    const attachmentFile = useRef(null)

    const handleClose = () => {
        setFlow({})
        onClose()
    }

    const handleAttachmentFile = async e => {
        const file = e.target.files[0]
        if (!file) return

        if (!['application/json', 'application/JSON'].includes(file.type))
            return toastError('O arquivo enviado é inválido! Envie um arquivo JSON')

        const uploadedFile = await uploadFile(file)
        setFlow(flow => ({...flow, file: uploadedFile}))
    }

    const handleSubmit = async (value) => {
        if (!value.file)
            return toastError('Não foi informado um arquivo para importar')

        try {
            const importedFlow = (await api.post(`/chat-flow/upload`, value))?.data
            toast.success('ChatBot importado com sucesso!')
            onChange(importedFlow)
            handleClose()
        } catch (e) {
            toastError('Erro ao importar ChatBot. Erro: ' + e.message)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
            <DialogTitle id="form-dialog-title">Importando ChatBot</DialogTitle>
            <Formik
                initialValues={flow}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ values, errors, isSubmitting }) => (
                    <Form>
                        <DialogContent dividers>
                            <Alert severity="info">
                                Os Campos Customizados e Tags faltantes serão
                                cadastrados automaticamente, Setores e Usuários
                                serão limpos!
                            </Alert>

                            <input
                                style={{ display: 'none' }}
                                type="file"
                                ref={attachmentFile}
                                accept="application/JSON"
                                onChange={e => handleAttachmentFile(e)}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    gap: 5,
                                    marginTop: 5,
                                }}>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        attachmentFile.current.click()
                                    }
                                    variant="outlined">
                                    Selecionar arquivo
                                </Button>

                                {flow.file?.originalname && (
                                    <Grid xs={6} item>
                                        <Button startIcon={<AttachFileIcon />}>
                                            {flow.file?.originalname}
                                        </Button>
                                    </Grid>
                                )}
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined">
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting || !flow.file?.blob}
                                variant="contained">
                                Importar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default ChatFlowImport
