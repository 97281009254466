import { useEffect, useState } from 'react'
import { Handle, Position, useEdges } from 'reactflow'

const CustomStartHandle = ({ nodeId, ...props }) => {
    const edges = useEdges()
    const [connectable, setConnectable] = useState(true)

    useEffect(() => {
        const alreadyUsed = edges.findIndex(
            ed =>
                ed.source === nodeId &&
                (!props.id || ed.sourceHandle === props.id),
        )
        if (alreadyUsed !== -1) return setConnectable(false)
        return setConnectable(true)
    }, [edges, nodeId, props.id])

    return (
        <Handle
            type="source"
            position={Position.Bottom}
            {...props}
            isConnectableStart={connectable}
            isConnectableEnd={connectable}
        />
    )
}

export default CustomStartHandle
