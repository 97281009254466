import AttachFileIcon from '@material-ui/icons/AttachFile'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Button, Grid, IconButton, TextField } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { i18n } from 'translate/i18n'
import useMediaUpload from 'hooks/useMediaUpload'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { Field } from 'formik'

const MessageForm = props => {
    const { uploadFile } = useMediaUpload()
    const [attachment, setAttachment] = useState(null)
    const attachmentFile = useRef(null)

    useEffect(() => {
        if (props.values?.file) setAttachment(props.values?.file.originalname)
    }, [props.values?.file])

    const handleAttachmentFile = async e => {
        deleteMedia() // Remove a mídia selecionada anteriormente

        const file = e.target.files[0]
        const uploadedFile = await uploadFile(file)

        props.setFieldValue('file', uploadedFile)
        setAttachment(uploadedFile.originalname)
    }

    const deleteMedia = async () => {
        if (props.values.file) {
            props.setFieldValue('deleteFile', { ...props.values.file })
            props.setFieldValue('file', null)
        } else if (props.values.mediaPath) {
            props.setFieldValue('deleteFile', {
                filename: props.values.mediaPath,
            })
            props.setFieldValue('mediaPath', null)
        }

        setAttachment(null)
    }

    return (
        <div>
            <Field
                as={TextField}
                variant="outlined"
                fullWidth
                name="title"
                label="Título"
                {...props.getFieldProps(`title`)}
                error={props.touched?.title && props.errors?.title}
                helperText={props.touched?.title && props.errors?.title}
                style={{ marginBottom: 10 }}
            />

            <MessageWithPicker
                name="message"
                label="Mensagem"
                required
                error={props.touched?.message && props.errors?.message}
                helperText={props.touched?.message && props.errors?.message}
                {...props.getFieldProps(`message`)}
            />

            {(props.values?.mediaPath || attachment) && (
                <Grid xs={12} item>
                    <Button startIcon={<AttachFileIcon />}>
                        {attachment ? attachment : props.values?.mediaPath}
                    </Button>
                    <IconButton
                        onClick={() => deleteMedia(true)}
                        color="secondary">
                        <DeleteOutlineIcon color="secondary" />
                    </IconButton>
                </Grid>
            )}

            <input
                style={{ display: 'none' }}
                type="file"
                ref={attachmentFile}
                onChange={e => handleAttachmentFile(e)}
            />

            <Button
                color="primary"
                onClick={() => attachmentFile.current.click()}
                variant="outlined">
                {i18n.t('quickMessages.buttons.attach')}
            </Button>
        </div>
    )
}

export default MessageForm
