import { Field, useField } from 'formik'
import UserSelect from 'components/UserSelect'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const FormikUserSelect = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    return <UserSelect {...field} {...props} onChange={handleChange} />
}

const UserForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={FormikUserSelect}
                    name="userId"
                    label="Atendente"
                    {...props.getFieldProps(`userId`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity='info' >Se o campo "Atendente" estiver vazio, o atendente será removido do ticket</Alert>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>
        </Grid>
    )
}

export default UserForm
